<template>
  <div>
    <div class="shadowed-box">
      <div class="box-content extra-padded">
        <div class="size-text-17px bold-weight bottom-5">
          Review & Send
        </div>

        <div class="bottom-30">
          Preview your RFP before sending.
        </div>

        <div v-if="quotesWithActivePartner.length" class="rfq-firms-wrapper">
          <div class="size-text-15px bold-weight bottom-5">
            Your Firms
          </div>

          <div class="bottom-20">
            Your law firms have provisioned the individuals displayed below to receive RFPs. If you would like to notify someone who is not listed, please use the link below to send an additional notification.
          </div>

          <div v-for="lawFirmQuote in quotesWithActivePartner" :key="lawFirmQuote.id" class="rfq-recipient-item">
            <div class="row tight-columns">
              <div class="col-sm-7 col-lg-8 semibold-weight">
                {{ lawFirmQuote.firmName }}
              </div>

              <div class="col-sm-5 col-lg-4 top-20-xs">
                <div class="d-flex-not-xs gap-30 flex-end">
                  <priori-modal ref="previewRfq" modal-id="preview-rfq" modal-size="lg" :title="previewModalTitle(lawFirmQuote)">
                    <template #modal-trigger="{ openModal }">
                      <a href="" class="bold-weight" @click.prevent="openModal">Preview RFP</a>
                    </template>

                    <rfq-preview :rfq="rfq" :law-firm-quote="lawFirmQuote"></rfq-preview>
                  </priori-modal>

                  <button type="button" class="pseudo-link-gray pill-gray-text semibold-weight top-10-xs" @click="toggleRecipients(lawFirmQuote.id)">
                    {{ isExpanded[lawFirmQuote.id] ? 'Hide' : 'View' }} Recipients<svg-icon name="caret-right" :class="['base-icon disclosure-icon', { 'expanded rotate-90': isExpanded[lawFirmQuote.id] }]"></svg-icon>
                  </button>
                </div>
              </div>
            </div>

            <div v-show="isExpanded[lawFirmQuote.id]" class="rfq-quote-recipients-wrapper top-20">
              <priori-modal
                :ref="`addAdditionalRecipientModal${lawFirmQuote.id}`"
                :modal-id="`add-additional-recipient-${lawFirmQuote.id}`"
                :backdrop="false"
                modal-size="md"
                title="Additional RFP Notification"
                @on-modal-close="resetModalForm(lawFirmQuote.id)">
                <template #modal-trigger="{ openModal }">
                  <rfq-recipients-list
                    :firm-name="lawFirmQuote.firmName"
                    :provisioned-lawyers="lawFirmQuote.lawyersToReceiveUpdates"
                    :additional-recipients="lawFirmQuote.additionalRecipients"
                    :on-remove-recipient="(additionalRecipients) => removeAdditionalRecipient(lawFirmQuote.id, additionalRecipients)"
                    :on-add-recipients="openModal">
                  </rfq-recipients-list>
                </template>

                <template #default="{ closeModal }">
                  <rfq-additional-recipients-form
                    save-button-text="Save"
                    :ref="`addAdditionalRecipientModalForm${lawFirmQuote.id}`"
                    :firm-name="lawFirmQuote.firmName"
                    :provisioned-emails="lawFirmQuote.lawyersToReceiveUpdates.map(l => l.email)"
                    :additional-recipients="lawFirmQuote.additionalRecipients"
                    :on-cancel="closeModal"
                    :on-save="(additionalRecipients) => addAdditionalRecipients(lawFirmQuote.id, additionalRecipients)">
                  </rfq-additional-recipients-form>
                </template>
              </priori-modal>
            </div>
          </div>
        </div>

        <div v-if="rfq.sentToMarketplace" :class="['sent-to-marketplace-wrapper', { 'with-scout-firms': quotesWithActivePartner.length }]">
          <div class="row vertical-center-not-xs">
            <div class="col-sm-9">
              <div class="size-text-15px bold-weight bottom-5">
                Priori’s Marketplace Network
              </div>
              Your RFP will be sent to Priori’s Marketplace. A Priori representative will reach out with information about next steps.
            </div>

            <div class="col-sm-3 text-right-not-xs">
              <priori-modal ref="previewRfq" modal-id="preview-rfq" modal-size="lg" :title="previewModalTitle(marketplaceLawFirmQuote)">
                <template #modal-trigger="{ openModal }">
                  <a href="" class="bold-weight" @click.prevent="openModal">Preview RFP</a>
                </template>

                <rfq-preview :rfq="rfq" :law-firm-quote="marketplaceLawFirmQuote"></rfq-preview>
              </priori-modal>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row vertical-center-not-xs top-30">
      <div class="col-sm-4 col-md-3">
        <button type="button" class="bold-weight gap-10 pseudo-link-blue vertical-center blue-text" @click="previous"><svg-icon name="arrow-left" class="base-icon"></svg-icon>Previous</button>
      </div>

      <div class="col-sm-4 col-sm-offset-4 col-md-3 col-md-offset-6 top-30-xs">
        <loading-button name="submitRfq" lb-class="primary-btn-blue" @lb-click="submit">Send RFP</loading-button>
      </div>
    </div>
  </div>
</template>

<script>
import RfqPreview from 'src/vue-app/scout/client/rfqs/preview.vue';
import PrioriModal from 'vue-app/shared/components/priori-modal.vue';
import RfqRecipientsList from 'vue-app/scout/client/rfqs/recipients-list.vue';
import RfqAdditionalRecipientsForm from 'vue-app/scout/client/rfqs/additional-recipients-form.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import LoadingButton from 'vue-app/shared/components/loading-button.vue';
import RFQLawFirmQuote from 'src/resources/scout/rfq-law-firm-quote.js';
import RFQ from 'src/resources/scout/rfq.js';
import LoadingService from 'vue-app/shared/services/loading-service.js';
import NotificationService from 'vue-app/shared/services/notification-service';
import modalToggle from 'vue-app/shared/mixins/modal-toggle.js';

export default {
  name: 'RfqStepReview',

  components: {
    PrioriModal,
    RfqAdditionalRecipientsForm,
    RfqRecipientsList,
    SvgIcon,
    RfqPreview,
    LoadingButton
  },

  mixins: [
    modalToggle
  ],

  props: {
    nextStep: {
      type: Object
    },

    prevStep: {
      type: Object
    },

    rfq: {
      type: Object,
      required: true
    }
  },

  data() {
    const expandedDefault = {};
    this.rfq.lawFirmQuotes.forEach((quote) => { expandedDefault[quote.id] = false; });
    return {
      isExpanded: expandedDefault
    };
  },

  computed: {
    quotesWithActivePartner() {
      return this.rfq.lawFirmQuotes.filter(quote => quote.firmHasRfqEnabled);
    },

    marketplaceLawFirmQuote() {
      return {
        firmName: 'Priori’s Marketplace'
      };
    }
  },

  methods: {
    resetModalForm(lawFirmQuoteId) {
      this.$refs[`addAdditionalRecipientModalForm${lawFirmQuoteId}`][0].reset();
    },

    addAdditionalRecipients(lawFirmQuoteId, additionalRecipients) {
      LoadingService.loading('saveRecipients');

      return RFQLawFirmQuote.addAdditionalRecipients({
        id: lawFirmQuoteId,
        rfqLawFirmQuote: { additionalRecipientsAttributes: additionalRecipients }
      })
      .then((response) => {
        const quote = this.rfq.lawFirmQuotes.find((quote) => quote.id === lawFirmQuoteId);
        quote.additionalRecipients = response.data.additionalRecipients;

        this.$refs[`addAdditionalRecipientModal${lawFirmQuoteId}`][0].closeModal();
      })
      .catch(() => {
        NotificationService.error('There was an issue adding recipients. Please try again.', false);
      })
      .finally(() => {
        LoadingService.done('saveRecipients');
      });
    },

    removeAdditionalRecipient(lawFirmQuoteId, additionalRecipientId) {
      return RFQLawFirmQuote.deleteAdditionalRecipient(lawFirmQuoteId, additionalRecipientId)
        .then((response) => {
          const quote = this.rfq.lawFirmQuotes.find((quote) => quote.id === lawFirmQuoteId);
          quote.additionalRecipients = response.data.additionalRecipients;
        });
    },

    toggleRecipients(quoteId) {
      this.isExpanded[quoteId] = !this.isExpanded[quoteId];
    },

    submit() {
      LoadingService.around('submitRfq', () => {
        return RFQ.submit({ rfq: this.rfq }).then((response) => {
          window.location.href = `/scout-company/rfps?newRfq=true&rfqId=${response.data.id}&rfqSentCount=${response.data.rfqSentCount}&sentToMarketplace=${response.data.sentToMarketplace}`;
        });
      });
    },

    previous() {
      this.prevStep.moveToPrevStep();
    },

    previewModalTitle(lawFirmQuote) {
      return `Preview RFP for ${lawFirmQuote.firmName}`;
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .rfq-firms-wrapper {
    padding: 20px;
    border: 1px solid $medium-gray;
    border-radius: $border-radius-large;
    border-left: 7px solid $k-blue;

    @media (min-width: $screen-sm-min) {
      padding: 20px 30px;
    }
  }

  .rfq-quote-recipients-wrapper {
    padding: 15px;
    background-color: $k-lighter-gray;
    border-radius: $border-radius-large;

    @media (min-width: $screen-sm-min) {
      padding: 20px 30px;
    }

    @media (min-width: $screen-lg-min) {
      padding: 30px 40px;
    }
  }

  .sent-to-marketplace-wrapper {
    padding: 20px 30px;
    border: 1px solid $medium-gray;
    border-radius: $border-radius-large;
    border-left: 7px solid $purple-5;

    &.with-scout-firms {
      margin-top: 20px;
    }
  }

  .rfq-recipient-item {
    border-top: 1px solid $medium-gray;
    padding-top: 20px;
    padding-bottom: 20px;

    &:last-child {
      padding-bottom: 0;
    }

    .base-icon {
      fill: $k-dark-gray;

      &:hover {
        fill: $k-darker-gray;
      }
    }
  }

  :deep(.mail-icon) {
    width: 13px;
    height: 13px;
  }
</style>
