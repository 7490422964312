/* eslint vue/component-definition-name-casing: "off" */

import Vue from 'vue';
import vueComponents from './vue-components';

/***********************/
/*  SHARED COMPONENTS  */
/***********************/

import Notification                     from 'vue-app/shared/components/notification.vue';
import TwoFactorAuthenticationSettings  from 'vue-app/shared/components/two-factor-authentication/two-factor-authentication-settings.vue';
import TwoFactorSetup                   from 'vue-app/shared/components/two-factor-authentication/two-factor-setup.vue';
import OtpLocked                        from 'vue-app/shared/components/two-factor-authentication/otp-locked.vue';
import OtpAttempt                       from 'vue-app/shared/components/two-factor-authentication/otp-attempt.vue';
import InterfaceNote                    from 'vue-app/shared/components/interface-note.vue';
import MultiSelectDropdown              from 'vue-app/shared/components/multi-select-dropdown.vue';
import DropdownSelectSections           from 'vue-app/shared/components/dropdown-select-sections.vue';
import DropdownSelect                   from 'vue-app/shared/components/dropdown-select.vue';
import PasswordSetupForm                from 'vue-app/shared/components/password-setup-form.vue';
import ImpersonationBubble              from 'vue-app/shared/components/impersonation-bubble.vue';
import PrioriPagination                 from 'vue-app/shared/components/priori-pagination.vue';
import SendMessageModal                 from 'vue-app/shared/components/send-message-modal.vue';
import SortSelect                       from 'vue-app/shared/components/sort-select.vue';
import DynamicOrderArrows               from 'vue-app/shared/components/dynamic-order-arrows.vue';
import TextareaWithUndo                 from 'vue-app/shared/components/textarea-with-undo.vue';

/************************/
/*  MARKETPLACE LAWYER  */
/************************/

import LawyerQuickActions               from 'vue-app/marketplace/lawyer/dashboard/lawyer-quick-actions.vue';
import OpportunityApplications          from 'vue-app/marketplace/lawyer/dashboard/opportunity-applications.vue';
import LawyerPreferencesWidget          from 'vue-app/marketplace/lawyer/dashboard/lawyer-preferences-widget.vue';
import LawyerReminders                  from 'vue-app/marketplace/lawyer/dashboard/lawyer-reminders.vue';
import RateForm                         from 'vue-app/marketplace/lawyer/shared/rate-form.vue';
import ClientRequests                   from 'vue-app/marketplace/lawyer/dashboard/client-requests.vue';
import RecentProjects                   from 'vue-app/marketplace/lawyer/dashboard/recent-projects.vue';
import DashboardTotals                  from 'vue-app/marketplace/lawyer/dashboard/dashboard-totals.vue';
import MarketplaceLawyerSettingsRoot    from 'vue-app/marketplace/lawyer/settings/root.vue';
import ProjectRatesTable                from 'vue-app/marketplace/lawyer/pricing-data/project-rates-table.vue';
import TopPracticesTable                from 'vue-app/marketplace/lawyer/pricing-data/top-practices-table.vue';
import PrioriRatesTable                 from 'vue-app/marketplace/lawyer/pricing-data/priori-rates-table.vue';
import VolumeWorkRatesTable             from 'vue-app/marketplace/lawyer/pricing-data/volume-work-rates-table.vue';
import LawyersShare                     from 'vue-app/marketplace/lawyer/documents/lawyers-share.vue';
import MarketplaceLawyerFirmsShow       from 'vue-app/marketplace/lawyer/firms/show.vue';
import LockReason                       from 'vue-app/marketplace/lawyer/project-settings/lock-reason.vue';

/************************/
/*  MARKETPLACE SHARED  */
/************************/

import LawyerEmploymentHistory          from 'vue-app/marketplace/shared/lawyers/lawyer-employment-history.vue';
import LawyerProfileSidebar             from 'vue-app/marketplace/shared/lawyers/lawyer-profile-sidebar.vue';
import LawyerProfile                    from 'vue-app/marketplace/shared/lawyers/lawyer-profile.vue';
import LawyerTestimonials               from 'vue-app/marketplace/shared/lawyers/lawyer-testimonials.vue';
import LawyerBio                        from 'vue-app/marketplace/shared/lawyers/lawyer-bio.vue';
import CallSchedule                     from 'vue-app/marketplace/shared/call-schedule/call-schedule.vue';
import AppointmentsNew                  from 'vue-app/marketplace/shared/appointments/scheduling.vue';
import AppointmentConfirmation          from 'vue-app/marketplace/shared/appointments/scheduling-confirmation.vue';
import ChooseTime                       from 'vue-app/marketplace/shared/appointments/choose-time.vue';
import ChooseTimeConfirmation           from 'vue-app/marketplace/shared/appointments/choose-time-confirmation.vue';
import EngagementLetterModal            from 'vue-app/marketplace/shared/engagement-letter-modal.vue';
import RfpFullFormStatic                from 'vue-app/marketplace/shared/requests/rfp-full-form-static.vue';
import ReviewLawyerForm                 from 'vue-app/marketplace/shared/review-lawyer-form.vue';

/******************/
/*  SCOUT CLIENT  */
/******************/

import FirmTitlebar                   from 'vue-app/scout/client/law-firms/firm-titlebar.vue';
import MatterGraphics                 from 'vue-app/scout/client/law-firms/matter-graphics.vue';
import RecentlyViewed                 from 'vue-app/scout/client/searches/recently-viewed.vue';
import ScoutClientDashboardIndex      from 'vue-app/scout/client/dashboard/index.vue';
import FirmNotesAndDocuments          from 'vue-app/scout/client/law-firms/firm-notes-and-documents.vue';
import MattersList                    from 'vue-app/scout/client/matters/matters-list.vue';
import ReviewsAverageScore            from 'vue-app/scout/client/shared/reviews-average-score.vue';
import MatterLawyer                   from 'vue-app/scout/client/matters/matter-lawyer.vue';
import GhostFirmDetails               from 'vue-app/scout/client/law-firms/ghost-firm-details.vue';
import ReviewDetails                  from 'vue-app/scout/client/reviews/review-details.vue';
import ScoutClientLawyerShow          from 'vue-app/scout/client/lawyers/show.vue';
import FirmOverview                   from 'vue-app/scout/client/law-firms/firm-overview.vue';
import FirmReviews                    from 'vue-app/scout/client/law-firms/firm-reviews.vue';
import FirmInvoiceTimelinessBreakdown from 'vue-app/scout/client/law-firms/firm-invoice-timeliness-breakdown.vue';
import FirmRelationshipPartners       from 'vue-app/scout/client/law-firms/firm-relationship-partners.vue';
import FirmLawyers                    from 'vue-app/scout/client/law-firms/firm-lawyers.vue';
import ScoutReviewSuccessModal        from 'vue-app/scout/client/reviews/success-modal.vue';
import ScoutSuccessListModal          from 'vue-app/scout/client/searches/success-list-modal.vue';
import LawFirmReviewNew               from 'vue-app/scout/client/reviews/new-law-firm.vue';
import LawyerReviewNew                from 'vue-app/scout/client/reviews/new-lawyer.vue';
import ScoutReviewsEdit               from 'vue-app/scout/client/reviews/edit.vue';
import ScoutReviewsShow               from 'vue-app/scout/client/reviews/show.vue';
import UsageAnalyticsIndex            from 'vue-app/scout/client/usage-analytics/usage-analytics-index.vue';
import FirmList                       from 'vue-app/scout/client/law-firms/firm-list.vue';
import AddFirmModal                   from 'vue-app/scout/client/law-firms/add-firm-modal.vue';
import MatterLawFirm                  from 'vue-app/scout/client/matters/matter-law-firm.vue';
import FilterActivityDate             from 'vue-app/scout/client/matters/filter-activity-date.vue';
import ClientMatterTitlebar           from 'vue-app/scout/client/matters/client-matter-titlebar.vue';
import ClientRfqIndex                 from 'vue-app/scout/client/rfqs/index.vue';
import ClientRfqShow                  from 'vue-app/scout/client/rfqs/show.vue';
import ClientRfqComparison            from 'vue-app/scout/client/rfqs/comparison.vue';
import ClientRfqCreate                from 'vue-app/scout/client/rfqs/create.vue';
import ListFirm                       from 'vue-app/scout/client/lists/list-firm.vue';
import SearchFirm                     from 'vue-app/scout/client/searches/search-firm.vue';
import SearchLawyer                   from 'vue-app/scout/client/searches/search-lawyer.vue';
import FirmLawyer                     from 'vue-app/scout/client/law-firms/firm-lawyer.vue';
import ScoutClientTeamsIndex          from 'vue-app/scout/client/teams/index.vue';
import ScoutClientSettingsIndex       from 'vue-app/scout/client/settings/index.vue';
import ScoutClientSettingsUsersIndex  from 'vue-app/scout/client/settings/users/index.vue';
import ScoutClientSettingsRfqTemplatesIndex from 'vue-app/scout/client/settings/rfqs/templates/index.vue';
import ScoutClientSettingsRfqTemplatesShow  from 'vue-app/scout/client/settings/rfqs/templates/show.vue';
import ScoutClientSettingsRfqTemplatesNew   from 'vue-app/scout/client/settings/rfqs/templates/new.vue';
import ScoutClientAccountIndex        from 'vue-app/scout/client/account/index.vue';
import ScoutClientReviewsIndex        from 'vue-app/scout/client/reviews/index.vue';
import ScoutClientDataUploadsIndex    from 'vue-app/scout/client/data-uploads/index.vue';
import ScoutClientDataUploadsShow     from 'vue-app/scout/client/data-uploads/show.vue';

/****************/
/*  SCOUT FIRM  */
/****************/

import ScoutFirmDashboardIndex     from 'vue-app/scout/firm/dashboard/index.vue';
import ScoutFirmAccountIndex       from 'vue-app/scout/firm/account/index.vue';

import FirmClientMatters           from 'vue-app/scout/firm/clients/firm-client-matters.vue';
import ScoutFirmLawyerShow         from 'vue-app/scout/firm/lawyers/show.vue';
import FirmClientLists             from 'vue-app/scout/firm/clients/firm-client-lists.vue';
import FirmClientCreateListForm    from 'vue-app/scout/firm/clients/firm-client-create-list-form.vue';
import ScoutFirmSettingsIndex      from 'vue-app/scout/firm/settings/index.vue';
import ScoutFirmUsers              from 'vue-app/scout/firm/settings/users.vue';
import DataLocksTable              from 'vue-app/scout/firm/data-locks-table.vue';
import ScoutProfileEditor          from 'vue-app/scout/firm/base-profile/scout-profile-editor.vue';
import RfqLawFirmQuotesIndex       from 'vue-app/scout/firm/rfqs/index.vue';
import RfqLawFirmQuotesIndexTable  from 'vue-app/scout/firm/rfqs/rfq-law-firm-quotes-index-table.vue';
import RfqLawFirmQuotesShow        from 'vue-app/scout/firm/rfqs/show.vue';
import ProfileDataPermission       from 'vue-app/scout/firm/profile-data-permission/profile-data-permission.vue';
import SearchLawyerGeneral         from 'vue-app/scout/firm/searches/search-lawyer-general.vue';
import SearchLawyerScoped          from 'vue-app/scout/firm/searches/search-lawyer-scoped.vue';

/******************/
/*  SCOUT SHARED  */
/******************/

import ListHeaderDetails            from 'vue-app/scout/shared/lists/header-details.vue';
import UploadModal                  from 'vue-app/scout/shared/uploads/upload-modal.vue';
import UploadList                   from 'vue-app/scout/shared/uploads/upload-list.vue';
import FirmProfile                  from 'vue-app/scout/shared/law-firms/firm-profile.vue';
import StatusBox                    from 'vue-app/scout/shared/status-box.vue';
import UploadErrorItem              from 'vue-app/scout/shared/uploads/upload-error-item.vue';
import FirmRepresentativeMatters    from 'vue-app/scout/shared/law-firms/firm-representative-matters.vue';
import PerformanceScoreWithTopLabel from 'vue-app/scout/shared/performance-score-with-top-label.vue';
import BudgetTracker                from 'vue-app/scout/shared/budget-tracker';
import LawyerDiversityInclusion     from 'vue-app/scout/shared/lawyers/lawyer-diversity-inclusion.vue';
import DataDisclaimer               from 'vue-app/scout/shared/data-disclaimer.vue';
import ProfilePhoto                 from 'vue-app/scout/shared/profile-photo.vue';
import LawyerNamePlate              from 'vue-app/scout/shared/lawyer-name-plate.vue';
import FirmNamePlate                from 'vue-app/scout/shared/firm-name-plate.vue';
import DataDisclaimerPanel          from 'vue-app/scout/shared/data-disclaimer-panel.vue';
import RatingCircle                 from 'vue-app/scout/shared/rating-circle.vue';
import CollapsableScoutTagsList     from 'vue-app/scout/shared/scout-tags/collapsable-scout-tags-list.vue';
import FormManageList               from 'vue-app/scout/shared/form-manage-list.vue';
import ListLawyer                   from 'vue-app/scout/shared/lists/list-lawyer.vue';
import MatchTag                     from 'vue-app/scout/shared/match-tag.vue';
import SearchFilter                 from 'vue-app/scout/shared/searches/search-filter.vue';

/************************/
/*  FEATURE LAB CLIENT  */
/************************/

import TermsOfService             from 'vue-app/feature-lab/client/terms-of-service/terms-of-service.vue';

/***********/
/*  ADMIN  */
/***********/

import AdminSettings                   from 'vue-app/admin/settings/index.vue';
import LawFirmContact                  from 'vue-app/admin/law-firms/law-firm-contact.vue';
import LawFirmClearDataModal           from 'vue-app/admin/law-firms/law-firm-clear-data-modal.vue';
import LawFirmUsers                    from 'vue-app/admin/law-firms/law-firm-users.vue';
import WorkspaceProductStatus          from 'vue-app/admin/workspaces/workspace-product-status.vue';
import FirmScoutStatus                 from 'vue-app/admin/law-firms/firm-scout-status.vue';
import ClientAccountTitlebar           from 'vue-app/admin/clients/client-account-titlebar.vue';
import LawFirmTitlebar                 from 'vue-app/admin/law-firms/law-firm-titlebar.vue';
import AdminWorkspaceUsers             from 'vue-app/admin/clients/admin-workspace-users.vue';
import AdminDashboard                  from 'vue-app/admin/dashboard/index.vue';
import AdminLawyerLeads                from 'vue-app/admin/lawyer-leads/index.vue';
import ClientCredits                   from 'vue-app/admin/clients/client-credits.vue';
import AccountEmployment               from 'vue-app/admin/lawyers/account-employment.vue';
import ImpersonateModal                from 'vue-app/admin/impersonate-modal.vue';
import AdminAppointmentsTable          from 'vue-app/admin/appointments/appointments-table.vue';
import AdminAppointmentsShow           from 'vue-app/admin/appointments/show.vue';
import AccountFirm                     from 'vue-app/admin/lawyers/account-firm.vue';
import AccountStatus                   from 'vue-app/admin/lawyers/account-status.vue';
import AccountList                     from 'vue-app/admin/lawyers/account-list.vue';
import AccountPermissions              from 'vue-app/admin/lawyers/account-permissions.vue';
import AdminLinks                      from 'vue-app/admin/toolbox/admin-links.vue';
import AdminInquiries                  from 'vue-app/admin/toolbox/admin-inquiries.vue';
import ScreeningTerms                  from 'vue-app/admin/toolbox/screening-terms.vue';
import MatterStatus                    from 'vue-app/admin/matters/matter-status.vue';
import ReferenceDetail                 from 'vue-app/admin/lawyers/reference-detail.vue';
import MatterTeamMembers               from 'vue-app/admin/matters/matter-team-members.vue';
import MatterLegalArrangementRider     from 'vue-app/admin/matters/matter-legal-arrangement-rider.vue';
import MatterClient                    from 'vue-app/admin/matters/matter-client.vue';
import ClientAccountStatus             from 'vue-app/admin/clients/client-account-status.vue';
import AccountMatching                 from 'vue-app/admin/lawyers/account-matching.vue';
import MatchingItem                    from 'vue-app/admin/matching/matching-item.vue';
import MatterInvoiceSummary            from 'vue-app/admin/matters/matter-invoice-summary.vue';
import MatterRates                     from 'vue-app/admin/matters/matter-rates.vue';
import MatterLedesDocuments            from 'vue-app/admin/matters/matter-ledes-documents.vue';
import MatterListLawyers               from 'vue-app/admin/matters/matter-list-lawyers.vue';
import AccountClientFeedback           from 'vue-app/admin/lawyers/account-client-feedback.vue';
import MatterTitlebar                  from 'vue-app/admin/matters/matter-titlebar.vue';
import AccountProficiency              from 'vue-app/admin/lawyers/account-proficiency.vue';
import LawyerListItem                  from 'vue-app/admin/lawyers/lawyer-list-item.vue';
import ClientListItem                  from 'vue-app/admin/clients/client-list-item.vue';
import WorkspaceListItem               from 'vue-app/admin/workspaces/workspace-list-item.vue';
import LawFirmListItem                 from 'vue-app/admin/law-firms/law-firm-list-item.vue';
import ScoutLawyerListItem             from 'vue-app/admin/scout-lawyers/scout-lawyer-list-item.vue';
import AccountTitlebar                 from 'vue-app/admin/lawyers/account-titlebar.vue';
import SearchVettingTags               from 'vue-app/admin/search/vetting-tags.vue';
import RequestBudget                   from 'vue-app/admin/requests/request-budget.vue';
import RequestReferralInfo             from 'vue-app/admin/requests/request-referral-info.vue';
import RequestInvitations              from 'vue-app/admin/requests/request-invitations.vue';
import RfqProjectDetails               from 'vue-app/admin/requests/rfq-project-details.vue';
import RfqAnonymizedProjectDescription from 'vue-app/admin/requests/rfq-anonymized-project-description.vue';
import RfqRecipientQuestions           from 'vue-app/admin/requests/rfq-recipient-questions.vue';
import BillingNoteListItem             from 'vue-app/admin/billing-notes/billing-note-list-item.vue';
import ProposalClientActivity          from 'vue-app/admin/requests/proposal-client-activity.vue';
import ProposalAdminActivity           from 'vue-app/admin/requests/proposal-admin-activity.vue';
import ProposalBioEditor               from 'vue-app/admin/requests/proposal-bio-editor.vue';
import ProposalLawyerSections          from 'vue-app/admin/requests/proposal-lawyer-sections.vue';
import ProposalFirmSections            from 'vue-app/admin/requests/proposal-firm-sections.vue';
import ProposalRateCalculator          from 'vue-app/admin/requests/proposal-rate-calculator.vue';
import ProposalRecipients              from 'vue-app/admin/requests/proposal-recipients.vue';
import WorkspaceTitlebar               from 'vue-app/admin/workspaces/workspace-titlebar.vue';
import WorkspaceOptions                from 'vue-app/admin/workspaces/workspace-options.vue';
import WorkspaceStatus                 from 'vue-app/admin/workspaces/workspace-status.vue';
import RequestDetails                  from 'vue-app/admin/requests/request-details.vue';
import RequestQualifications           from 'vue-app/admin/requests/request-qualifications.vue';
import RequestAdmin                    from 'vue-app/admin/requests/request-admin.vue';
import RequestQuotes                   from 'vue-app/admin/requests/request-quotes.vue';
import RequestTitlebar                 from 'vue-app/admin/requests/request-titlebar.vue';
import TrackersIndex                   from 'vue-app/admin/requests/trackers-index.vue';
import NewCredit                       from 'vue-app/admin/clients/new-credit.vue';
import LeadDetails                     from 'vue-app/admin/lawyer-leads/lead-details.vue';
import LeadTitlebar                    from 'vue-app/admin/lawyer-leads/lead-titlebar.vue';
import StatusKeys                      from 'vue-app/admin/shared/status-keys.vue';
import SearchSort                      from 'vue-app/admin/shared/search-sort.vue';
import TableSort                       from 'vue-app/admin/shared/table-sort.vue';
import SearchPagination                from 'vue-app/admin/shared/search-pagination.vue';
import AdminClientAccountInfo          from 'vue-app/admin/clients/client-account-info.vue';
import AdminWorkspaceInfo              from 'vue-app/admin/workspaces/workspace-info.vue';
import WorkspaceBillingNote            from 'vue-app/admin/workspaces/workspace-billing-note.vue';
import LeadStatus                      from 'vue-app/admin/lawyer-leads/lead-status.vue';
import SearchHighlights                from 'vue-app/admin/search/search-highlights.vue';
import AdminSavedSearchesIndex         from 'vue-app/admin/saved-searches/index.vue';
import RequestList                     from 'vue-app/admin/requests/request-list.vue';
import MatterPaymentSetup              from 'vue-app/admin/matters/matter-payment-setup.vue';
import AdminSavedSearchModal           from 'vue-app/admin/saved-searches/saved-search-modal.vue';
import AdminSearchFilterValues         from 'vue-app/admin/shared/admin-search-filter-values.vue';
import AdminProposalsShow              from 'vue-app/marketplace/client/proposals/show.vue';
import OpportunityContainer            from 'vue-app/admin/opportunities/container.vue';

/************/
/*  PUBLIC  */
/************/

import LawyerOpportunitiesIndex from 'vue-app/public/opportunities/index.vue';
import LawyerOpportunitiesShow  from 'vue-app/public/opportunities/show.vue';
import ClientSignUpForm         from 'vue-app/public/client-sign-up-form.vue';
import PasswordResetForm        from 'vue-app/public/password-reset-form.vue';
import InvitedClientSignUpForm  from 'vue-app/public/invited-client-sign-up-form.vue';
import PublicProposalsShow      from 'vue-app/marketplace/client/proposals/show.vue';

const components = {
  AccountClientFeedback,
  AccountEmployment,
  AccountFirm,
  AccountList,
  AccountMatching,
  AccountPermissions,
  AccountProficiency,
  AccountStatus,
  AccountTitlebar,
  AddFirmModal,
  AdminAppointmentsShow,
  AdminAppointmentsTable,
  AdminClientAccountInfo,
  AdminDashboard,
  AdminInquiries,
  AdminLawyerLeads,
  AdminLinks,
  AdminProposalsShow,
  AdminSavedSearchesIndex,
  AdminSavedSearchModal,
  AdminSearchFilterValues,
  AdminSettings,
  AdminWorkspaceInfo,
  AdminWorkspaceUsers,
  AppointmentConfirmation,
  AppointmentsNew,
  BillingNoteListItem,
  BudgetTracker,
  CallSchedule,
  ChooseTime,
  ChooseTimeConfirmation,
  ClientAccountStatus,
  ClientAccountTitlebar,
  ClientCredits,
  ClientListItem,
  ClientMatterTitlebar,
  ClientRequests,
  ClientRfqComparison,
  ClientRfqCreate,
  ClientRfqIndex,
  ClientRfqShow,
  ClientSignUpForm,
  CollapsableScoutTagsList,
  DashboardTotals,
  DataDisclaimer,
  DataDisclaimerPanel,
  DataLocksTable,
  DropdownSelect,
  DropdownSelectSections,
  DynamicOrderArrows,
  EngagementLetterModal,
  FilterActivityDate,
  FirmClientCreateListForm,
  FirmClientLists,
  FirmClientMatters,
  FirmInvoiceTimelinessBreakdown,
  FirmLawyer,
  FirmLawyers,
  FirmList,
  FirmNamePlate,
  FirmNotesAndDocuments,
  FirmOverview,
  FirmProfile,
  FirmRelationshipPartners,
  FirmRepresentativeMatters,
  FirmReviews,
  FirmScoutStatus,
  FirmTitlebar,
  FormManageList,
  ImpersonationBubble,
  ImpersonateModal,
  InterfaceNote,
  InvitedClientSignUpForm,
  GhostFirmDetails,
  LawFirmContact,
  LawFirmClearDataModal,
  LawFirmListItem,
  LawFirmReviewNew,
  LawFirmTitlebar,
  LawFirmUsers,
  LawyerBio,
  LawyerDiversityInclusion,
  LawyerEmploymentHistory,
  LawyerListItem,
  LawyerNamePlate,
  LawyerOpportunitiesIndex,
  LawyerOpportunitiesShow,
  LawyerPreferencesWidget,
  LawyerProfile,
  LawyerProfileSidebar,
  LawyerQuickActions,
  LawyerReminders,
  LawyerReviewNew,
  LawyersShare,
  LawyerTestimonials,
  LeadDetails,
  LeadStatus,
  LeadTitlebar,
  ListFirm,
  ListHeaderDetails,
  ListLawyer,
  LockReason,
  MarketplaceLawyerFirmsShow,
  MarketplaceLawyerSettingsRoot,
  MatchingItem,
  MatchTag,
  MatterClient,
  MatterGraphics,
  MatterInvoiceSummary,
  MatterLawyer,
  MatterLedesDocuments,
  MatterLegalArrangementRider,
  MatterLawFirm,
  MatterListLawyers,
  MatterPaymentSetup,
  MatterRates,
  MatterStatus,
  MatterTeamMembers,
  MatterTitlebar,
  MattersList,
  MultiSelectDropdown,
  NewCredit,
  Notification,
  OpportunityApplications,
  OpportunityContainer,
  OtpAttempt,
  OtpLocked,
  PasswordResetForm,
  PasswordSetupForm,
  PerformanceScoreWithTopLabel,
  PrioriPagination,
  PrioriRatesTable,
  ProfileDataPermission,
  ProfilePhoto,
  ProjectRatesTable,
  ProposalAdminActivity,
  ProposalBioEditor,
  ProposalClientActivity,
  ProposalFirmSections,
  ProposalLawyerSections,
  ProposalRateCalculator,
  ProposalRecipients,
  PublicProposalsShow,
  RateForm,
  RatingCircle,
  RecentProjects,
  RecentlyViewed,
  ReferenceDetail,
  RequestAdmin,
  RequestBudget,
  RequestDetails,
  RequestInvitations,
  RequestList,
  RequestQualifications,
  RequestQuotes,
  RequestReferralInfo,
  RequestTitlebar,
  ReviewDetails,
  ReviewLawyerForm,
  ReviewsAverageScore,
  RfqAnonymizedProjectDescription,
  RfpFullFormStatic,
  RfqLawFirmQuotesIndex,
  RfqLawFirmQuotesIndexTable,
  RfqLawFirmQuotesShow,
  RfqProjectDetails,
  RfqRecipientQuestions,
  SearchFirm,
  ScoutClientAccountIndex,
  ScoutClientDashboardIndex,
  ScoutClientDataUploadsIndex,
  ScoutClientDataUploadsShow,
  ScoutClientLawyerShow,
  ScoutClientReviewsIndex,
  ScoutClientSettingsIndex,
  ScoutClientSettingsRfqTemplatesIndex,
  ScoutClientSettingsRfqTemplatesNew,
  ScoutClientSettingsRfqTemplatesShow,
  ScoutClientSettingsUsersIndex,
  ScoutClientTeamsIndex,
  ScoutReviewSuccessModal,
  ScreeningTerms,
  SearchFilter,
  ScoutFirmAccountIndex,
  ScoutFirmDashboardIndex,
  ScoutFirmLawyerShow,
  ScoutFirmSettingsIndex,
  ScoutFirmUsers,
  ScoutLawyerListItem,
  ScoutProfileEditor,
  ScoutReviewsEdit,
  ScoutReviewsShow,
  ScoutSuccessListModal,
  SearchHighlights,
  SearchLawyer,
  SearchLawyerGeneral,
  SearchLawyerScoped,
  SearchPagination,
  SearchSort,
  SearchVettingTags,
  SendMessageModal,
  SortSelect,
  StatusBox,
  StatusKeys,
  TableSort,
  TermsOfService,
  TextareaWithUndo,
  TopPracticesTable,
  TrackersIndex,
  TwoFactorAuthenticationSettings,
  TwoFactorSetup,
  UploadErrorItem,
  UploadList,
  UploadModal,
  UsageAnalyticsIndex,
  VolumeWorkRatesTable,
  WorkspaceBillingNote,
  WorkspaceListItem,
  WorkspaceOptions,
  WorkspaceStatus,
  WorkspaceProductStatus,
  WorkspaceTitlebar
};

Object.entries(components).forEach(([componentName, component]) => {
  vueComponents.directive(`ngv${componentName}`, ['createVueComponent', function (createVueComponent) {
    return createVueComponent(Vue.component(componentName, component));
  }]);
});
